import React, { useState } from "react";
import {  Text, Stack, Box } from "@chakra-ui/react";
import "./navbar.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "./useWindowDimension";


export default function Navbar() {
  const [active, setActive] = useState(true);
  const { _, width } = useWindowDimensions();

  const changeNavCol = () => {
    if (window.scrollY >= 80) {
      setActive(true);
    } else {
      setActive(false);
    }
  };
  window.addEventListener("scroll", changeNavCol);

  let LogicalSpot;
  if (width > 480) {
    LogicalSpot = (
      <Text
        className="logicalspot"
        as={Link}
        to="/"
        paddingEnd={"2"}
        paddingStart={"2"}
      >
        @iamhemant
      </Text>
    );
  } else {
    LogicalSpot = (
      <Text
        className="logicalspot small"
        as={Link}
        to="/"
        paddingEnd={"2"}
        paddingStart={"1.2"}
      >
        @iamhemant
      </Text>
    );
  }

  return (
    <>
      <Stack
        direction={"row"}
        className={active ? "navbar" : "navbar active"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box className="titleOfsite">{LogicalSpot}</Box>
        {/* <p>w:{width} h:{height}</p> 
        <Box className="CornerArea">
          <IconButton
            as={Link}
            to="/"
            // colorScheme={"facebook"}
            color={"#6a6299"}
            fontSize={"3xl"}
            variant={"link"}
            icon={<FaHome />}
            paddingEnd={"4"}
            paddingStart={"2"}
          />
          
          <Link to='/nft'>
            <button className="nftBtn">
              <img alt="nftpage" src={require("../nfticon2.png")} />
            </button>
          </Link>
          
        </Box>*/}
      </Stack>
    </>
  );
}

import React from 'react'
import './projectListCard.css'
import ProjectCard from './ProjectCard'

export default function ProjectListCard() {
  return (
    <div className='projectList'>
      <div className='projectItems'>
          <ProjectCard 
          name="Bloomee🌸" 
          image={require("../../assets/banner2BloomeScrnShot.png")} 
          subtitle="Bloomee is an Ad-free, Open-source and Online/Offline Music Player for Android. Download Now!"
          download="https://bloomee.sourceforge.io"
          linkto="https://github.com/HemantKArya/BloomeeTunes/"
          />
          <ProjectCard 
          name="Melodfy🎵" 
          image={require("../../assets/b1.png")} 
          subtitle="Melodfy is a An AI based program that convert piano audio to digital MIDI file with High Accuracy in you local Computer."
          download="https://sourceforge.net/projects/melodfy/"
          linkto="https://github.com/HemantKArya/Melodfy"
          />
          <ProjectCard 
          name="MIDI-Mamba" 
          image={require("../../assets/midimamba.webp")} 
          subtitle="An GenAI MIDI Composer that can generate MIDI music notes with the help of 160M parameter Generative AI model (Trained from scratch)."
          linkto="https://github.com/HemantKArya/MIDI_Mamba"
          download="https://huggingface.co/krystv/MIDI_Mamba-159M"
          />
          <ProjectCard 
          name="lsHotword" 
          image={require("../../assets/lshot.png")} 
          subtitle="lsHotword is Wake Word detector and Easy to use Python Module Which is open-Source with GUI based trainer module."
          linkto="https://github.com/HemantKArya/lsHotword"
          download="https://pypi.org/project/lsHotword/"
          />
          
          
        
          </div>
    </div>
  )
}

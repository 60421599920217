import "./Latest.css";
import React from "react";
import GlassBtn from "./Customs/GlassBtn";
import useWindowDimensions from "./useWindowDimension";
import {
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaMailBulk,
} from "react-icons/fa";
import Typewriter from "typewriter-effect";
import { ScaleFade, Stack, Text, VStack } from "@chakra-ui/react";
import ProjectListCard from "./Customs/ProjectListCard";

export default function Latest() {
  const { height, width } = useWindowDimensions();
  let smallTag;
  let smallTagDyn;
  let BtnStackDirection;
  if (width > 480) {
    smallTag = "title";
    smallTagDyn = "ttlFill";
  } else {
    smallTag = "title small";
    smallTagDyn = "ttlFill small";
  }
  if (width > 640) {
    BtnStackDirection = "row";
  } else {
    BtnStackDirection = "column";
  }

  return (
    <VStack className="latest">
      <Text className={smallTag}>
        <Typewriter
          options={{ autoStart: true, delay: 45, deleteSpeed: 30, cursor: "" }}
          onInit={(typewriter) => {
            typewriter

              .typeString("CRAFTING TOMORROW'S SOLUTIONS WITH")

              .pauseFor(1200)
              .start();
          }}
        />
      </Text>

      <Text className={smallTagDyn}>
        <Typewriter
          options={{
            loop: true,
            autoStart: true,
            delay: 45,
            deleteSpeed: 30,
            cursor: "█",
          }}
          onInit={(typewriter) => {
            typewriter

              .typeString("QT6 With Python.")
              
              .pauseFor(1200)
              .deleteAll(30)
              .typeString("Gen AI with PyTorch & TensorFlow.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("C & C++ for performance critical code.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("Flutter and BloC Patterns.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("MongoDB and PostgreSQL etc.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("Flask for RESTful APIs.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("Rust for fast and safe code.")

              .pauseFor(1200)
              .deleteAll(30)
              .typeString("ReactJS with Redux/MobX.")
              .pauseFor(1200)
              .deleteAll(30)
              .typeString("GraphQL with Ariadne.")

              .pauseFor(1200)
              .start();
          }}
        />
      </Text>

      <ProjectListCard/>

      {/* <Text className='title'>WITH AI.</Text> */}
      <ScaleFade in={true}>
        <Stack className="btns" direction={BtnStackDirection} alignItems={"center"}>
          <GlassBtn
            text="YouTube"
            linkto="https://www.youtube.com/@LogicalSpot"
            icon={<FaYoutube />}
          />
          <GlassBtn
            text="Instagram"
            linkto="https://www.instagram.com/iamhemantindia"
            icon={<FaInstagram />}
          />
          <GlassBtn
            text="Github"
            linkto="https://www.github.com/HemantKArya"
            icon={<FaGithub />}
          />
          <GlassBtn
            text="Twitter"
            linkto="https://www.twitter.com/iamhemantindia"
            icon={<FaTwitter />}
          />
          {/* <Button as='a' href='mailto:iamhemantindia@protonmail.com' target='_blank' rel='noreferrer' _active={{bg:'none'}} _hover={{bg:'none'}} boxShadow={'0 0 10px 0px white'} variant={'outline'} leftIcon={<FaMailBulk/>} borderWidth={'2px'} borderRadius={'3xl'} colorScheme={'pink'} className='BtnSignup' textColor={'antiquewhite'}>Mail</Button> */}
          {/* <GlassBtn linkto="https://www.google.co.in" text='Github' icon={<FaGithub/>}/> */}
          <GlassBtn
            linkto="mailto:iamhemantindia@protonmail.com"
            text="Mail"
            icon={<FaMailBulk />}
          />
        </Stack>
      </ScaleFade>


    </VStack>

  );
}

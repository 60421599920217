import "./GlassBtn.css";
import React from "react";

export default function GlassBtn(props) {
  return (
    <a target="_blank" rel="noreferrer" href={props.linkto}>
      <button className="GlassBtn">
        <div className="gbtndiv">
          <div className="gbtnicon">{props.icon}</div>
          {props.text}
        </div>
      </button>
    </a>
  );
}

import Home from './components/Home'
import './App.css';
import './Apps.scss'
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Footer from './components/Footer'
import Navbar from './components/Navbar';



function App() {
  return (
    <Router>
    <div class="main-container">
  <div class="sub-container">
    <div class="sky">
      <div class="stars"></div>
      <div class="stars2"></div>
      <div class="stars3"></div>
      
    
      <Navbar/>
      <Routes>
                 <Route exact path='/' element={<Home/>}></Route>
                 {/* <Route exact path='/contact' element={< Contact />}></Route> */}
      </Routes>
      <Footer/>
      </div>
  </div>
 
</div>
    </Router>
  );
}

export default App;

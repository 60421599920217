import React from "react";
import "./Home.css";
import Latest from "./Latest";
// import {Button,Text} from '@chakra-ui/react';
// import logo from '../logo.svg';

export default function Home() {
  return (
    <div className="info">
      <Latest />
    </div>
  );
}

import './projectCardStyle.css'

import { Box, Card, CardHeader, CardFooter, Heading,Text, VStack } from "@chakra-ui/react";
import { ScaleFade } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";

import React from 'react'

export default function ProjectCard(props) {
  return (
    <a href={props.linkto} target={"_blank"} rel={"noreferrer"}>
    <Card className='projectcard'>
        <ScaleFade in={true}>
            <Card
            borderRadius={"20px"}
            variant={["elevated"]}
            backgroundColor={"#21142e"}
            >
            <Box className="imgcont" borderRadius={"21px"} bgColor={"antiquewhite"}>
                <img
                objectFit="cover"
                src={props.image}
                alt={props.image}
                borderTopRadius={"lg"}
                />
            </Box>
            <CardHeader marginTop={"-3"}>
                <Heading fontFamily={"Poppins"} color={"#c0cdef"} size="md">
                {props.name}
                </Heading>
            </CardHeader>
            
            <CardFooter marginTop={"-5"} marginLeft={"-2"}>
                <VStack alignItems={'flex-start'}>
            <Text className='cardSubTtl'>
                    {props.subtitle}
                </Text>
                <a href={props.download} target={"_blank"} rel={"noreferrer"}>
            <button className="toOpenSea">
              <div className="Btnicon">
                <FaEye />
              </div>
              Download Now
            </button>
          </a></VStack>
            </CardFooter>
            </Card>
        </ScaleFade>
    </Card>
    </a>
  )
}
